import useUserStore from "@/store/modules/user";
// import { showToast } from "@nutui/nutui";
import router from "../router";

function directive(app) {
  app.directive("loginedClick", {
    mounted(el, binding) {
      if (typeof binding.value !== "function") return;
      el.handler = function () {
        console.log("loginedClick");
        const userStore = useUserStore();
        if (!userStore.isLogined) {
          // showToast.text("请先登录");
          router.push("/login");
        } else {
          binding.value.apply(this, arguments);
        }
      };
      el.addEventListener("click", el.handler);
    },
    beforeUnmount(el) {
      el.removeEventListener("click", el.handler);
    },
  });
}

export default directive;
