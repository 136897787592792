/**
 * 区域配置接口
 */
import request from "../utils/request";

/**
 * 区域列表
 * @param {*} parameter
 * @returns
 */
export function regionList(parameter) {
  return request({
    url: "/datacenter/mapRegionConfig/list",
    method: "get",
    params: parameter,
  });
}
