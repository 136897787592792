/**
 * 文件相关接口
 */
import request, { baseURL } from "../utils/request-platform";
import { OSS_TYPE } from "../config/defaultSettings";

/**
 * 上传的文件最大值
 */
export const fileMax = {
  image: 3, // 图片类型3M
  file: 100, // 一般文件100M
};

/**
 * 文件上传
 * @param {*} parameter
 * @returns
 */
export const fileLoad =
  baseURL + (OSS_TYPE === 2 ? "/oos/fileUpload" : "/minio-oss/fileUpload");

/**
 * 下载文件
 * @param {*} parameter
 * @returns
 */
export function downloadFile(parameter) {
  return request({
    url: OSS_TYPE === 2 ? "/oos/download" : "/minio-oss/download",
    method: "get",
    params: parameter,
    responseType: "blob",
  });
}

/**
 * 天翼云 - 读取地址（有过期时间）
 * @param {*} parameter
 * @returns
 */
export function generatePresignedUrl(parameter) {
  return request({
    url: "/oos/generatePresignedUrl",
    method: "get",
    params: parameter,
  });
}
