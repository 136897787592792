import router from "./router";
import useUserStore from "@/store/modules/user";
import { ACCESS_TOKEN } from "@/config/constant";
import storage from "store";
const allowList = [
  "/home",
  "/my-center",
  "/login",

  "/selection-mall",
  "/product-details",
  "/selection-list",
  "/account-check",
  "/sign-in",
  "/sign-up",
  "/password-setting",
];

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const token = storage.get(ACCESS_TOKEN);
  console.log(token);
  if (token) {
    next();
    if (!userStore.token) {
      userStore.getLocalInfo();
    } else {
      try {
        await userStore.RefreshToken();
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    if (allowList.indexOf(to.path) > -1) {
      next();
    } else {
      next({ path: "/" });
    }
  }
});

router.afterEach((to) => {
  document.title = to.meta.title;
});
