import axios from "axios";
import { showNotify } from "@nutui/nutui";
import { createHeaders } from "./tool";
import storage from "store";
import { ACCESS_TOKEN } from "@/config/constant";
import useUserStore from "@/store/modules/user";
import router from "@/router";

/**
 * 所有环境的api地址前缀
 */
const ALL_ENV_BASEURL = {
  dev: "http://10.10.1.143:8892",
  test: "https://api.test.gyyscr.com/platform_website",
  prod: "https://api.gyyscr.com/platform_website",
};

/**
 * 所有环境的oss资源地址前缀
 */
const ALL_ENV_OSSURL = {
  dev: "http://10.10.1.240:8888",
  test: "https://oss.test.gyfzpt.com",
  prod: "https://oss.gyfzpt.com",
};

export const baseURL = ALL_ENV_BASEURL[process.env.VUE_APP_API_ENV];
export const ossURL = ALL_ENV_OSSURL[process.env.VUE_APP_API_ENV];

/**
 * 接口请求统一处理
 * @param {*} baseURL
 * @param {*} accessKey
 * @param {*} secretKey
 * @returns
 */
function createRequest(baseURL) {
  // 创建axios实例
  const request = axios.create({
    // API 请求的默认前缀
    baseURL: baseURL,
    // 请求超时时间
    timeout: 10000,
  });

  request.interceptors.request.use(
    (config) => {
      // 在发送请求之前做些什么
      // console.log(config)
      config.headers = {
        ...config.headers,
        ...createHeaders(),
      };
      // console.log(config)

      // 如果接口不需要授权
      if (config.noAuth) {
        delete config.headers["Authorization"];
      }

      return config;
    },
    (error) => {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );

  request.interceptors.response.use(
    (response) => {
      console.log("response", response);
      // 对响应数据做点什么
      const userStore = useUserStore();
      const token = storage.get(ACCESS_TOKEN);
      if (!response.data) {
        showNotify.danger("网络异常，请稍后重试");
      } else {
        if (response.data.code === 401) {
          showNotify.danger(response.data.msg || "登录信息失效，请重新登录");
          if (token) {
            // && !response.config.noAuth
            userStore.quit("onlyDeleteCache").then(() => {
              router.push("/");
            });
          }
        }
      }
      return response.data;
    },
    (error) => {
      console.log("error.response", error);
      if (error.response?.config?.logout) return Promise.reject(error);
      const userStore = useUserStore();
      const token = storage.get(ACCESS_TOKEN);
      // 对响应错误做点什么
      if (error.response?.status === 401) {
        showNotify.danger(
          error.response.data.message || "登录信息失效，请重新登录"
        );
        if (token) {
          userStore.quit("onlyDeleteCache").then(() => {
            router.push("/");
          });
        }
      } else {
        console.log("qqqqq");
        showNotify.danger("网络异常，请稍后重试");
      }
      return Promise.reject(error);
    }
  );

  return request;
}

const request = createRequest(baseURL);

export default request;
