/**
 * 企业管理接口
 */
import request from "../utils/request";

/**
 * 企业列表 - 分页
 * @param {*} parameter
 * @returns
 */
export function companyPage(parameter) {
  return request({
    url: "/datacenter/companyInfo/page",
    method: "get",
    params: parameter,
  });
}

/**
 * 新增企业
 * @param {*} parameter
 * @returns
 */
export function companySave(parameter) {
  return request({
    url: "/datacenter/companyInfo/save",
    method: "post",
    data: parameter,
  });
}

/**
 * 修改企业
 * @param {*} parameter
 * @returns
 */
export function companyUpdate(parameter) {
  return request({
    url: "/datacenter/companyInfo/update",
    method: "post",
    data: parameter,
  });
}

/**
 * 企业详情
 * @param {*} parameter
 * @returns
 */
export function companyDetail(parameter) {
  return request({
    url: "/datacenter/companyInfo/detail",
    method: "get",
    params: parameter,
  });
}
/**
 * 企业跟踪记录列表
 * @param {*} parameter
 * @returns
 */
export function companyRecordList(parameter) {
  return request({
    url: "/datacenter/mapInterviewRecord/list",
    method: "get",
    params: parameter,
  });
}

/**
 * 更新是否展示
 * @param {*} parameter
 * @returns
 */
export function companyDisplay(parameter) {
  return request({
    url: "/datacenter/companyInfo/updateDisPlay",
    method: "post",
    params: parameter,
  });
}

/**
 * 删除
 * @param {*} parameter
 * @returns
 */
export function companyRemove(parameter) {
  return request({
    url: "/datacenter/companyInfo/remove",
    method: "post",
    params: parameter,
  });
}

/**
 * 查询大屏展示企业类型
 * @param {*} parameter
 * @returns
 */
export function getDataVCompanyType(parameter) {
  return request({
    url: "/datacenter/dataV/getCompanyType",
    method: "get",
    params: parameter,
  });
}

/**
 * 大屏展示企业类型保存
 * @param {*} parameter
 * @returns
 */
export function saveDataVCompanyType(parameter) {
  return request({
    url: "/datacenter/dataV/saveCompanyType",
    method: "post",
    data: parameter,
  });
}

/**
 * 用户申请企业
 * @param {*} parameter
 * @returns
 */
export function userCompanySave(parameter) {
  return request({
    url: "/datacenter/mapCompanyRequest/add",
    method: "post",
    data: parameter,
  });
}

/**
 * 用户查看提交记录列表
 * @param {*} parameter
 * @returns
 */
export function userCompanyList(parameter) {
  return request({
    url: "/datacenter/mapCompanyRequest/list",
    method: "get",
    params: parameter,
  });
}

/**
 * 详情
 * @param {*} parameter
 * @returns
 */
export function userCompanyDetail(parameter) {
  return request({
    url: "/datacenter/mapCompanyRequest/detail",
    method: "get",
    params: parameter,
  });
}

/**
 * 用户修改
 * @param {*} parameter
 * @returns
 */
export function userCompanyUpdate(parameter) {
  return request({
    url: "/datacenter/mapCompanyRequest/updateForUser",
    method: "post",
    data: parameter,
  });
}
