import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "/home",
    component: () => import("../views/home/Index.vue"),
    meta: { title: "首页" },
  },
  {
    path: "/my-center",
    name: "/my-center",
    component: () => import("../views/user/MyCenter.vue"),
    meta: { title: "我的" },
  },
  {
    path: "/login",
    name: "/login",
    component: () => import("../views/login/SignIn.vue"),
    meta: { title: "登录/注册" },
  },
  {
    path: "/company-info-apply",
    name: "/company-info-apply",
    component: () => import("../views/company/InfoApply.vue"),
    meta: { title: "企业信息申报" },
  },
  {
    path: "/company-info-list",
    name: "/company-info-list",
    component: () => import("../views/company/InfoList.vue"),
    meta: { title: "企业申报列表" },
  },
  {
    path: "/company-info-detail",
    name: "/company-info-detail",
    component: () => import("../views/company/InfoDetail.vue"),
    meta: { title: "企业信息详情" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
