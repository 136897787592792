/**
 * 高德地图 - web服务 - api
 */
import request from "../utils/request-platform";

/**
 * 行政区划
 * @param {*} parameter
 * @returns
 */
export function getAmapDistrict() {
  return request({
    url: "/gaodedata/region",
    method: "get",
  });
}
