import {
  Button,
  Image,
  Badge,
  Empty,
  Popup,
  Dialog,
  Input,
  Toast,
  Notify,
  Tabbar,
  TabbarItem,
  Cell,
  CellGroup,
  Avatar,
  Form,
  FormItem,
  Textarea,
  Picker,
  Checkbox,
  CheckboxGroup,
  Tag,
  Noticebar,
} from "@nutui/nutui";

export default function useNutui(app) {
  app.use(Button);
  app.use(Image);
  app.use(Badge);
  app.use(Empty);
  app.use(Popup);
  app.use(Dialog);
  app.use(Input);
  app.use(Toast);
  app.use(Notify);
  app.use(Tabbar);
  app.use(TabbarItem);
  app.use(Cell);
  app.use(CellGroup);
  app.use(Avatar);
  app.use(Form);
  app.use(FormItem);
  app.use(Textarea);
  app.use(Picker);
  app.use(Checkbox);
  app.use(CheckboxGroup);
  app.use(Tag);
  app.use(Noticebar);
}
