/**
 * 字典管理
 */
import request from "../utils/request-platform";

/**
 * 字典数据 - 列表
 * @param {*} parameter
 * @returns
 */
export function dictDataList(parameter) {
  return request({
    url: "/system/sysDictData/list",
    method: "get",
    params: parameter,
  });
}
