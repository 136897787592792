/**
 * token
 */
export const ACCESS_TOKEN = getEnvWords("Frontend-Access-Token");

/**
 * 用户
 */
export const USER_INFO = getEnvWords("User-Info");

/**
 * 购物车已选择的商品
 */
export const SELECTION_CART = getEnvWords("Selection-Cart");

/**
 * 手机号
 */
export const USER_ACCOUNT = getEnvWords("User-Account");

/**
 * 字典类型 - 企业类型
 */
export const COMPANY_TYPE = "COMPANY_TYPE";

/**
 * 根据不同环境得到不同的值
 */
function getEnvWords(key) {
  return process.env.VUE_APP_API_ENV === "prod"
    ? key
    : process.env.VUE_APP_API_ENV + "-" + key;
}
