/**
 * 当前系统id
 */
export const SYSTEM_ID = 1;

/**
 *系统注册来源
 */
export const REGISTER_SOURCE = 7;

/**
 * 文件对象存储服务
 * 自行搭建 minio - 1 天翼云 ctyun - 2
 */
export const OSS_TYPE = +process.env.VUE_APP_OOS_TYPE;
