import request from "@/utils/request-platform";

/**
 * 账号验证码方式注册或登录
 * @param {*} parameter
 * @returns
 */
export function userCodeLoginOrRegister(parameter) {
  return request({
    url: "/user/login/codeLoginOrRegister",
    method: "post",
    data: parameter,
  });
}

/**
 * 发送短信验证码
 * @param {*} parameter
 * @returns
 */
export function smsCode(parameter) {
  return request({
    url: "/sms/sendVerifyCode",
    method: "post",
    data: parameter,
  });
}

/**
 * 刷新token
 * @param {*} parameter
 * @returns
 */
export function refreshToken(parameter) {
  return request({
    url: "/system/refreshToken",
    method: "get",
    params: parameter,
  });
}

/**
 * 用户登出
 * @param {*} parameter
 * @returns
 */
export function adminLogout(parameter) {
  return request({
    url: "/system/logout",
    method: "get",
    params: parameter,
    logout: true,
    timeout: 5000,
  });
}
